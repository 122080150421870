<template>
  <div class="page page-login"></div>
</template>

<script>
import * as Cookies from 'js-cookie';

export default {
  created() {
    let hash = {};
    window.location.hash.substring(1).split('&')
        .map((parameter) => hash[parameter.split('=')[0]] = parameter.split('=')[1]);
    Cookies.set('access_token', hash['access_token'], {
      sameSite: 'None',
      secure: true,
    });

    this.$router.push({
      name: 'home',
    });
  },
};
</script>

<style>

</style>
